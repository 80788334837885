<template>
	<div class="d-flex flex-column justify-content-between login-container">
		<div class="login-head">
			<div>
				<img alt="Logo" :src="layoutConfig('self.logo.default')" class="logo-default max-h-70px" />
			</div>
		</div>
		<div class="login-body d-flex justify-content-center" v-if="showLogin">
			<!--begin::Signin-->
			<div class="login-form">
				<!-- <b-form @submit.stop.prevent="onSubmit">
					<h1 class="text-center">VSAT Portal Login</h1>
					<div>
						<div role="alert" :class="{ show: error }" class="alert fade alert-danger">
							<div class="alert-text">{{ error }}</div>
						</div>
					</div>
					<b-form-input
						id="username"
						name="username"
						placeholder="Username"
						v-model="$v.form.username.$model"
						:state="validateState('username')"
						aria-describedby="username-live-feedback"
						class="mb-5"
						autofocus
					/>
					<b-form-input
						type="password"
						placeholder="Password"
						id="password"
						name="password"
						v-model="$v.form.password.$model"
						:state="validateState('password')"
						aria-describedby="password-live-feedback"
						class="mb-5"
					/> -->
					<div class="row justify-content-center">
						<div>
							<b-button @click="loginClicked()" variant="primary" :class="[{ 'spinner spinner-white spinner-right': loggingIn }]">
								Login
							</b-button>
						</div>
					</div>
				<!-- </b-form> -->
			</div>
			<!--end::Signin-->
		</div>
		<transition name="fade-in-up">
			<div class="login-body d-flex justify-content-center" v-if="showPassword">
				<div class="login-form">
					<b-form @submit.stop.prevent="updatePw">
						<h1 class="text-center">Welcome</h1>
						<h5 class="font-weight-normal text-center text-danger my-5">Please update your password</h5>
						<div role="alert" :class="{ show: error }" class="alert fade alert-danger mb-3" v-if="error">
							<div class="alert-text">{{ error }}</div>
						</div>
						<b-form-group description="Minimum 8 characters & must include one of each: lowercase letter, uppercase letter, number, and special character.">
							<b-form-input
								type="password"
								id="newPw"
								ref="newPw"
								name="newPw"
								placeholder="New Password"
								v-model="$v.changePw.newPw.$model"
								:state="validatePw('newPw')"
								aria-describedby="username-live-feedback"
								autofocus
								trim
							/>
						</b-form-group>
						<b-form-input
							type="password"
							placeholder="Re-enter Password"
							id="validatePw"
							name="validatePw"
							v-model="$v.changePw.validatePw.$model"
							:state="validatePw('validatePw')"
							aria-describedby="password-live-feedback"
							class="mb-5"
						/>
						<div class="row justify-content-center">
							<div>
								<b-button type="submit" variant="primary" :class="[{ 'spinner spinner-white spinner-right': loggingIn }, 'px-10']">
									Save
								</b-button>
							</div>
						</div>
					</b-form>
				</div>
			</div>
		</transition>
		<!--end::Body-->
		<div class="pull-right login-footer">
			<div class="d-flex justify-content-between">
				<div>Copyright &copy; {{ new Date().getFullYear() }} Marlink</div>
				<div>
					<b-link href="https://marlink.com/privacy-policy-notice/?itcBecomesMarlink" target="_blank">Privacy Policy</b-link>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.login-container {
	background-color: rgb(245, 251, 255);
	height: 100%;
}
.login-head {
	padding: 30px 50px;
}
.login-form {
	width: 250px;
}
.login-footer {
	padding: 50px 50px 25px;
}
</style>

<script>
import { mapGetters } from 'vuex';
import { LOGIN, PURGE_AUTH } from '@/core/services/store/auth.module';
import { GET_USER_PROFILE } from '@/core/services/store/profile.module';
import { validationMixin } from 'vuelidate';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import { login } from '../../../authUtils.js'

export default {
	mixins: [validationMixin],
	name: 'Login',
	data() {
		return {
			form: {
				username: null,
				password: null,
			},
			changePw: {
				newPw: null,
				validatePw: null,
				error: false,
			},
			loggingIn: false,
			error: null,
			showPassword: false,
			showLogin: true,
		};
	},
	validations: {
		form: {
			username: {
				required,
			},
			password: {
				required,
			},
		},
		changePw: {
			newPw: {
				required,
				minLength: minLength(8),
			},
			validatePw: {
				required,
				sameAsPassword: sameAs('newPw'),
			},
		},
	},
	methods: {
		loginClicked() {
			
			login();
		},
		validateState(name) {
			const { $dirty, $error } = this.$v.form[name];
			return $dirty ? !$error : null;
		},
		validatePw(pw) {
			const { $dirty, $error } = this.$v.changePw[pw];
			return $dirty ? !$error : null;
		},
		onSubmit() {
			this.$v.form.$touch();
			if (this.$v.form.$anyError) {
				return;
			}
			this.loggingIn = true;

			// clear existing errors
			this.$store.commit(PURGE_AUTH);
			const username = this.$v.form.username.$model;
			const password = this.$v.form.password.$model;
			this.$store
				.dispatch(LOGIN, { username, password })
				.then(() => {
					this.$store.dispatch(GET_USER_PROFILE).then(() => {
						if (this.requiresPwUpdate) {
							this.error = null;
							this.showPassword = true;
							this.showLogin = false;
							this.loggingIn = false;
						} else {
							if (this.$route.query.redirect) {
								this.$router.push({ path: this.$route.query.redirect });
							} else {
								this.$router.push({ name: 'dash' });
							}
						}
					});
				})
				.catch(err => {
					if (err.status == 401) {
						this.error = 'Incorrect username or password';
					} else {
						this.error = err.data;
					}
					this.$v.form.password.$model = '';
					this.loggingIn = false;
				});
		},
		updatePw() {
			this.$v.changePw.$touch();
			if (this.$v.changePw.$anyError) {
				return;
			}
			this.$http
				.post('/changepw', { username: this.$v.form.username.$model, pass: this.changePw.newPw })
				.then(resp => {
					if (resp.status == 200) {
						this.$store.dispatch(LOGIN, { username: this.$v.form.username.$model, password: this.changePw.newPw }).then(() => {
							if (this.$route.query.redirect) {
								this.$router.push({ path: this.$route.query.redirect });
							} else {
								this.$router.push({ name: 'dash' });
							}
						});
					} else {
						console.log(data.data);
					}
				})
				.catch(error => {
					if (error.response.status == 406) {
						this.changePw.newPw = '';
						this.changePw.validatePw = '';
						this.error = 'This password is too common, please choose a more secure password';
						this.$refs.newPw.focus();
					}
					if (error.response.status == 400) {
						this.changePw.newPw = '';
						this.changePw.validatePw = '';
						this.error = 'Password must include one of each: lowercase letter, uppercase letter, number, and special character.';
						this.$refs.newPw.focus();
					}

				});
		},
	},
	computed: {
		...mapGetters(['layoutConfig', 'isAuthenticated', 'requiresPwUpdate']),
		backgroundImage() {
			return process.env.BASE_URL + 'assets/media/bg/bg-4.jpg';
		},
	},
	mounted() {
		// login();
	}
};
</script>
